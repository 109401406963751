export default class Global {
    constructor() {
        setTimeout(() => {
            this.googleTranslateElementInit();
            this.loadStoredLanguage();


            const skiptranslate = document.querySelector('.skiptranslate');

            if (skiptranslate) {
                skiptranslate.style.display = 'none';
                document.body.style.top = '0';
            }
        }, 1000);

        this.initLanguageToggle();
    }

    googleTranslateElementInit() {
        new google.translate.TranslateElement({
            pageLanguage: 'nl',
            layout: google.translate.TranslateElement.InlineLayout.SIMPLE
        }, 'google_translate_element');
    }

    handleLanguage(language, imagePath, languageName) {
        console.log(language, imagePath, languageName);
        let selectField = document.querySelector("select.goog-te-combo");
        if (selectField) {
            selectField.value = language;
            selectField.dispatchEvent(new Event('change'));
        }

        document.getElementById("currentLanguageImg").src = imagePath;
        document.getElementById("currentLanguageImg").alt = languageName;

        if (language !== 'nl') {
            sessionStorage.setItem('selectedLanguage', JSON.stringify({
                language: language,
                imagePath: imagePath,
                languageName: languageName
            }));
        } else {
            sessionStorage.removeItem('selectedLanguage');
        }

        this.userSelectedLanguage = true;
        sessionStorage.setItem('userSelectedLanguage', true);

        setTimeout(() => {
            const skiptranslate = document.querySelector('.skiptranslate');

            if (skiptranslate) {
                skiptranslate.style.display = 'none';
                document.body.style.top = '0';
            }
        }, 1000);
    }

    loadStoredLanguage() {
        const storedLang = sessionStorage.getItem('selectedLanguage');
        const userSelectedLanguage = sessionStorage.getItem('userSelectedLanguage');

        if (storedLang && userSelectedLanguage) {
            const { language, imagePath, languageName } = JSON.parse(storedLang);
            this.handleLanguage(language, imagePath, languageName);
        }
    }

    changeLanguage(language, imagePath, languageName) {
        this.handleLanguage(language, imagePath, languageName);
    }

    initLanguageToggle() {
        document.querySelector('.lang-display').addEventListener('click', () => {
            const currentLanguageImg = document.getElementById('currentLanguageImg');
            const currentLanguage = currentLanguageImg.alt;

            if (currentLanguage === 'Nederlands') {
                this.changeLanguage('en', "/img/uk.png", 'English');
            } else {
                this.changeLanguage('nl', "/img/nl.png", 'Nederlands');
            }
        });
    }
}

const globalInstance = new Global();
window.changeLanguage = (language, imagePath, languageName) => globalInstance.changeLanguage(language, imagePath, languageName);
