export default class Lazyload {

    constructor() {
        this.lazyload();
    }

    lazyload() {
        const items = document.querySelectorAll('[data-src]');
        let elements = new Array();

        for (var i = 0; i < items.length; i++) {
            if (!items[i].classList.contains('swiper-lazy')) {
                elements.push(items[i]);
            }
        };

        this.processScroll(elements);
        window.addEventListener('scroll', () => {
            this.processScroll(elements);
        });
    }

    processScroll(elements) {
        for (var i = 0; i < elements.length; i++) {
            if (this.elementInViewport(elements[i])) {
                this.loadImage(elements[i], function () {
                    elements.splice(i, 1);
                });
            }
        }
    }

    loadImage(el, fn) {
        let src = el.getAttribute('data-src');

        if (el instanceof HTMLImageElement) {
            if (!el.src) {
                if (window.getComputedStyle(el, null).display == 'inline') {
                    el.style.display = 'inline-block';
                }

                el.src = src;
            }
        } else {
            if (!el.style.backgroundImage) {
                el.style.backgroundImage = 'url(' + src + ')';
            }
        }
    }

    elementInViewport(el) {
        let rect = el.getBoundingClientRect();

        return (
            (rect.top >= 0 && rect.top <= (window.innerHeight || document.documentElement.clientHeight)) ||
            (rect.bottom >= 0 && rect.bottom <= (window.innerHeight || document.documentElement.clientHeight))
        );
    }
}
