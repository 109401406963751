import Global from "./global";
import Navigation from "./partials/navigation";
import Aside from "./partials/aside";
import Lazyload from "./lazyload";
import Banner from "./banners/banner";
import Fancybox from "./banners/fancybox";
import customModal from "./assets/custom-modal";
import Edition from "./editions/edition";

import Tools from "./pages/tools";
import Whitepaper from "./whitepapers/whitepaper";

// Global
new Global();

//partials
new Navigation();
new Aside();

//Banners
new Fancybox();
new Banner();

//Pages
new Tools();

//assets
new customModal();

//editions
new Edition();

//whitepapers
new Whitepaper();

//Lazy loading
new Lazyload();
