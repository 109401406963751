import Helper from "../helper";
import Axios from "axios";

export default class Edition {
    constructor() {
        if (document.getElementById('edition-download-modal')) {
            this.init();
            this.modal();
            this.document;
            this.url;
        }
    }

    init() {
        const toggleButtons = document.querySelectorAll('[data-toggle="custom-modal"][data-target="edition-download-modal"]');
        const modal = document.getElementById('edition-download-modal');

        toggleButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();

                const image = button.dataset.image;
                const document = button.dataset.document;
                const url = button.dataset.url;

                this.document = document;
                this.url = url;

                const img = modal.querySelector('img');

                if (img) {
                    img.src = image;
                }

                const isSessionSet = sessionStorage.getItem('editionSession');
                if (isSessionSet) {
                    // Session is set, open the document immediately and skip the popup
                    window.open(this.document, '_blank');
                    modal.classList.add('hide');
                    return;
                }
            })
        })
    }

    modal() {
        const modal = document.getElementById('edition-download-modal');
        const alertBox = modal.querySelector('.message-box');
        const emailInput = modal.querySelector('form input[name="email"]');
        const firstNameInput = modal.querySelector('form .firstName');
        const lastNameInput = modal.querySelector('form .lastName');
        const toeleverancierCheckbox = modal.querySelector('form .toeleverancier');
        const button = modal.querySelector('form button');

        button.addEventListener('click', (e) => {
            e.preventDefault();

            const email = emailInput.value;
            const firstName = firstNameInput.value;
            const lastName = lastNameInput.value;

            if (email.length == 0 || !Helper.validateEmail(email)) {
                alertBox.innerHTML = Helper.createAlert('Vul een geldig e-mailadres in.', 'alert-danger').outerHTML;
                return;
            }

            let postData = {
                email: email,
                firstName: firstName,
                lastName: lastName
            };

            if (toeleverancierCheckbox.checked) {
                postData.toeleverancier = toeleverancierCheckbox.value;
            }

            Axios.post(this.url, postData).then(function (response) {
                sessionStorage.setItem('editionSession', true);
                modal.classList.add('hide');
            }).catch(function (error) {
                console.log(error);
            });

            window.open(this.document, '_blank');
        });
    }
}
