export default class customModal {
    constructor() {
        this.init();
    }

    init() {

        const modals = document.querySelectorAll('.custom-modal');
        //close button for modals
        modals.forEach((modal) => {
            const close = modal.querySelector('.close');

            if (close) {
                close.addEventListener('click', (ev) => {
                    ev.preventDefault();

                    modal.classList.add('hide');
                });
            }
        });

        //toggle buttons
        const toggleButtons = document.querySelectorAll('[data-toggle="custom-modal"]');

        toggleButtons.forEach((button) => {
            button.addEventListener('click', function (e) {
                e.preventDefault();

                const target = button.dataset.target;

                const modal = document.getElementById(target);

                if (modal) {
                    modal.classList.remove('hide');
                }
            })
        });
    }
}
