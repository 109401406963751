import Swiper from "swiper";
import Axios from "axios";

export default class Banner {
    constructor() {
        this.slides();
        this.click();
    }

    slides() {
        const bannersWrapper = document.querySelectorAll('.banners-wrapper');

        bannersWrapper.forEach(function (bannerWrapper) {
            const banners = bannerWrapper.querySelectorAll('.banner');

            banners.forEach(function (banner, index) {
                if (index > 0) {
                    banner.classList.add('d-none');
                }
            });

            setInterval(function () {

                let shouldSkip = false;

                banners.forEach(function (banner, index) {
                    if (shouldSkip) {
                        return;
                    }

                    if (!banner.classList.contains('d-none')) {
                        banner.classList.add('d-none');

                        const nextIndex = index + 1;

                        const nextBanner = banners[nextIndex] ? banners[nextIndex] : banners[0];

                        nextBanner.classList.remove('d-none')

                        shouldSkip = true;
                    }
                });
            }, 5000);

        });
    }

    click() {
        const bannersWrapper = document.querySelectorAll('.banners-wrapper');

        bannersWrapper.forEach(function (bannerWrapper) {
            const banners = bannerWrapper.querySelectorAll('.banner');

            banners.forEach(function (banner, index) {
                banner.addEventListener('click', function (e) {
                    const url = banner.dataset.url;
                    const id = banner.dataset.id;
                    const website = banner.dataset.website;
                    
                    Axios.post('/banner/click', {
                        banner_id: id
                    }).then(function (response) {

                    }).catch(function (error) {
                        console.log(error);
                    })

                    if (url) {
                        window.open(url, '_blank');
                    }
                })
            });
        });
    }
}
