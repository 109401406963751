import Helper from "../helper";

export default class Tools {
    constructor() {
        this.btwTool()
        this.salesTool();
    }

    btwTool() {
        const form = document.querySelector('#btwTool');

        if (form) {
            const empty = form.querySelector('.emptyFields');

            empty.addEventListener('click', function (e) {
                e.preventDefault();

                form.querySelectorAll('input[type=text]').forEach((input) => {
                    input.value = '';
                })
            })


            form.querySelectorAll('input[type=text]').forEach((input) => {
                Helper.setInputFilter(input, function (value) {
                    return /^-?\d*[.,]?\d*$/.test(value);
                }, "Het moet een getal zijn.");

            })

            form.addEventListener('submit', (e) => {
                e.preventDefault();

                const btw = form.querySelector('input[name=btw]:checked');

                if (!btw) {
                    return false;
                }

                const btwValue = btw.value;

                const priceExVat = form.querySelector('input[name=price-ex-vat]');
                const salesTax = form.querySelector('input[name=sales-tax]');
                const price = form.querySelector('input[name=price]');

                const priceExVatValueActive = parseFloat(priceExVat.value.replace(',', '.'));
                const salesTaxValueActive = parseFloat(salesTax.value.replace(',', '.'));
                const priceValueActive = parseFloat(price.value.replace(',', '.'));

                let priceExVatValue = priceExVatValueActive;
                let salesTaxValue = salesTaxValueActive;
                let priceValue = priceValueActive;

                if (priceExVatValueActive) {
                    salesTaxValue = priceExVatValue * (btwValue / 100);
                    salesTaxValue = Math.round(salesTaxValue * 100) / 100;
                    priceValue = priceExVatValue + salesTaxValue;
                }

                if (salesTaxValueActive) {
                    priceExVatValue = (salesTaxValue / btwValue) * 100;
                    priceValue = priceExVatValue + salesTaxValue;
                }

                if (priceValueActive) {
                    priceExVatValue = (priceValue / (100 + Number(btwValue)) * Number(btwValue));
                    salesTaxValue = priceExVatValue;
                    priceExVatValue = priceValue - salesTaxValue;
                }

                if (!isNaN(priceExVatValue)) {
                    priceExVat.value = priceExVatValue.toFixed(2).replace('.', ',');
                }

                if (!isNaN(salesTaxValue)) {
                    salesTax.value = salesTaxValue.toFixed(2).replace('.', ',');
                }

                if (!isNaN(priceValue)) {
                    price.value = priceValue.toFixed(2).replace('.', ',');
                }
            })

        }
    }

    salesTool() {
        const form = document.querySelector('#saleTool');

        if (form) {
            const empty = form.querySelector('.emptyFields');

            empty.addEventListener('click', function (e) {
                e.preventDefault();

                form.querySelectorAll('input[type=text]').forEach((input) => {
                    input.value = '';
                })
            })

            form.querySelectorAll('input[type=text]').forEach((input) => {
                Helper.setInputFilter(input, function (value) {
                    return /^-?\d*[.,]?\d*$/.test(value);
                }, "Het moet een getal zijn.");

            })

            form.addEventListener('submit', (e) => {
                e.preventDefault();

                const btw = form.querySelector('input[name=btw]:checked');

                if (!btw) {
                    return false;
                }

                const btwValue = btw.value;

                const purchasePrice = form.querySelector('input[name=purchase-price]');
                const profitPercentage = form.querySelector('input[name=profit-percentage]');
                const salePrice = form.querySelector('#salePrice');

                const purchasePriceValueActive = parseFloat(purchasePrice.value.replace(',', '.'));
                const profitPercentageValueActive = parseFloat(profitPercentage.value.replace(',', '.'));

                let purchasePriceValue = purchasePriceValueActive;
                let profitPercentageValue = profitPercentageValueActive;

                if (purchasePriceValueActive && profitPercentageValueActive) {
                    purchasePriceValue = purchasePriceValue * (((Number(btwValue) / 100) + 1) * ((Number(profitPercentageValue) / 100) + 1));
                }

                if (!isNaN(purchasePriceValue) && profitPercentageValueActive) {
                    salePrice.innerHTML = purchasePriceValue.toFixed(2).replace('.', ',');
                }
            })

        }
    }
}
