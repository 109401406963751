import Helper from "../helper";

export default class Fancybox {
    constructor() {
        this.fancybox();
    }

    fancybox() {
        const popup = document.querySelector('#fancybox-popup');

        if (popup) {
            const timeout = parseInt(popup.dataset.seconds) * 1000;
            const scrollY = isNaN(parseInt(popup.dataset.pixels)) ? 0 : parseInt(popup.dataset.pixels);
            const timer = window.setTimeout(showPopup, timeout);

            if (scrollY > 0) {
                const scroll = window.addEventListener('scroll', showPopup);
            }
            
            function showPopup() {
                if (Helper.getCookie('hideFancybox') == null && scrollY <= window.scrollY) {
                    popup.classList.remove('hide');

                    window.removeEventListener('scroll', showPopup);
                    clearTimeout(timer);

                    Helper.setCookie('hideFancybox', true, 180);
                }
            }

            //closing popup
            const close = popup.querySelector('.close');

            close.addEventListener('click', function () {
                //hide popup for 180 min = 3 hours
                Helper.setCookie('hideFancybox', true, 180);

                popup.classList.add('hide');
            });

        }
    }
}
