export default class Navigation {

    constructor() {
        this.nav();
        this.profile();
    }


    nav() {
        const header = document.querySelector('header');
        const html = document.querySelector('html');
        const body = document.querySelector('body');

        const rubricsDropdown = document.querySelector('#header-rubrics #rubrics-dropdown');
        const rubrics = document.querySelector('#header-rubrics nav ul');

        window.onscroll = function () {
            let scrollPosY = window.pageYOffset | document.body.scrollTop;

            if (scrollPosY > 100) {
                header.classList.add('active')
                // body.classList.add('nav-fixed');
            } else {
                header.classList.remove('active')
                // body.classList.remove('nav-fixed');
            }
        }

        if (window.innerWidth <= 1200) {
            // Toggle navigation with nav button
            const nav = document.querySelector('header nav');
            const btn = document.querySelector('header .nav-toggle');

            btn.addEventListener('click', function () {
                btn.classList.toggle('active');
                nav.classList.toggle('active');
                body.classList.toggle('nav-open');

                if (nav.classList.contains('active')) {
                    html.style.overflow = 'hidden';
                } else {
                    html.style.overflow = 'initial';
                }
            });

            // Toggle navigation with subnavigation
            // const listItems = document.querySelectorAll('header nav ul li.has-children');
            //
            // listItems.forEach(function (el, i) {
            //     let subNav;
            //     var clicked = false;
            //     subNav = el.querySelector('ul');
            //     subNav.setAttribute('data-height', subNav.clientHeight);
            //     subNav.style.height = 0;
            //
            //     el.querySelector('a').addEventListener('click', function (e) {
            //         if (!clicked) {
            //             e.preventDefault();
            //             el.classList.toggle('active');
            //
            //             if (el.classList.contains('active')) {
            //                 subNav.style.height = subNav.getAttribute('data-height') + 'px';
            //             } else {
            //                 subNav.style.height = 0;
            //             }
            //         }
            //         clicked = !clicked;
            //     });
            // });

            if (rubricsDropdown) {
                rubricsDropdown.addEventListener('click', (e) => {
                    e.preventDefault();

                    rubrics.classList.toggle('active');
                })
            }
        }
    }

    profile() {
        if (document.querySelector('header ul.profile')) {
            const profile = document.querySelector('header ul.profile li');
            const children = profile.querySelector('ul');

            document.addEventListener('click', function (event) {
                if (!profile.contains(event.target)) {
                    children.classList.remove('active');
                }
            });

            profile.addEventListener('click', function () {
                children.classList.toggle('active');
            });
        }
    }
}
