import Helper from "../helper";
import Axios from "axios";

export default class Whitepaper {
    constructor() {
        if (document.getElementById('whitepaper-download-modal')) {
            this.init();
            this.modal();
            this.document;
            this.url;
        }
    }

    init() {
        const toggleButtons = document.querySelectorAll('[data-toggle="custom-modal"][data-target="whitepaper-download-modal"]');
        const modal = document.getElementById('whitepaper-download-modal');

        toggleButtons.forEach((button) => {
            button.addEventListener('click', (e) => {
                e.preventDefault();

                const image = button.dataset.image;
                const document = button.dataset.document;
                const emailAccount = button.dataset.emailaccount;
                const whitepaper = button.dataset.whitepaper;
                const url = button.dataset.url;

                this.document = document;
                this.url = url;
                this.whitepaper = whitepaper;
                this.emailAccount = emailAccount;

                const img = modal.querySelector('img');

                if (img) {
                    img.src = image;
                }

                const emailAccountInput = modal.querySelector('form .email-account');
                emailAccountInput.value = emailAccount;

                const whitepaperInput = modal.querySelector('form .whitepaper');
                whitepaperInput.value = whitepaper;

                const isSessionSet = sessionStorage.getItem('whitePaperSession');
                if (isSessionSet) {
                    // Session is set, open the document immediately and skip the popup
                    window.open(this.document, '_blank');
                    modal.classList.add('hide');
                    return;
                }
            })
        })
    }

    modal() {
        const modal = document.getElementById('whitepaper-download-modal');
        const alertBox = modal.querySelector('.message-box');
        const email = modal.querySelector('form .email');
        const firstName = modal.querySelector('form .firstName');
        const lastName = modal.querySelector('form .lastName');
        const toeleverancier = modal.querySelector('form .toeleverancier');
        const website = modal.querySelector('form .website');
        const whitepaper = modal.querySelector('form .whitepaper');
        const emailAccount = modal.querySelector('form .email-account');
        const button = modal.querySelector('form button');

        button.addEventListener('click', (e) => {
            e.preventDefault();

            const emailVal = email.value;
            const firstNameVal = firstName.value;
            const lastNameVal = lastName.value;
            const websiteVal = website.value;
            const whitepaperVal = whitepaper.value;
            const emailAccountVal = emailAccount.value;

            let postData = {
                email: emailVal,
                firstName: firstNameVal,
                lastName: lastNameVal,
                website: websiteVal,
                whitepaper: whitepaperVal,
                emailAccount: emailAccountVal
            };

            if (toeleverancier.checked) {
                postData.toeleverancier = toeleverancier.value;
            }

            if (emailVal.length == 0 || !Helper.validateEmail(emailVal)) {
                alertBox.innerHTML = Helper.createAlert('Vul een geldig e-mailadres in.', 'alert-danger').outerHTML;
                return;
            }

            Axios.post(this.url, postData).then(function (response) {
                sessionStorage.setItem('whitePaperSession', true);
                modal.classList.add('hide');
            }).catch(function (error) {
                console.log(error);
            });

            window.open(this.document, '_blank');
        });
    }
}
